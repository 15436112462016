
import { Component, Vue } from 'vue-property-decorator';
import rulesService from '@/services/rules.service';
import DeleteDialog from '@/components/dialogs/delete-dialog.component.vue';
import AlertError from '@/components/alert-error/alert-error.component.vue';
import SubstatusService from '../../services/substatus.service';
import CompaniesService from '../../../Companies/services/companies.service';
import { Substatus } from '../../entities/substatus.entity';
import { Company } from '../../../Companies/entities/company.entity';
import { Order } from '../../../Orders/entities/order.entity';
import OrderService from '../../../Orders/services/order.service';
import statusService from '../../../Status/services/status.service';
import { Status } from '../../../Status/entities/status.entity';

@Component({ components: { AlertError, DeleteDialog } })
export default class StatusCreateComponent extends Vue {
  public $refs: any;

  private substatus: Substatus = new Substatus();

  private companies: Company[] = [];

  private statusList: Status[] = [];

  private showDialogDelete: boolean = false;

  private errors: any[] = [];

  private statusOption: any[] = [
    {
      id: 0,
      name: 'Inativo',
    },
    {
      id: 1,
      name: 'Ativo',
    },
  ];

  private rules: any = {
    required: rulesService.required,
  };

  private get hasError(): boolean {
    return Object.keys(this.errors).length > 0;
  }

  private get labelFormType() {
    return this.isEdit ? 'Editar' : 'Cadastrar';
  }

  private get isEdit() {
    const { id } = this.$route.params;
    return !!id;
  }

  private async getStatusById() {
    const { id } = this.$route.params;
    const substatus = await SubstatusService.getStatusById(id);
    this.substatus = new Substatus(substatus);
    this.$loader.close();
  }

  private setShowDialogDelete() {
    this.showDialogDelete = true;
  }

  private closeDialogDelete() {
    this.showDialogDelete = false;
  }

  private deleteItem() {
    this.substatus
      .delete()
      .then(() => {
        this.$snackbar.open({
          text: 'Status excluído com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
        this.goToList();
      })
      .catch((err: any) => {
        this.$snackbar.open({
          text: err.response.data.message,
          color: 'danger',
          buttonColor: '#fff',
        });
        throw new Error(err);
      })
      .finally(() => {
        this.closeDialogDelete();
      });
  }

  private async setStatus(status: Status) {
    this.substatus.status = status;
  }

  private async getOrderStatus() {
    this.statusList = await statusService.getStatusByCompanyId(this.substatus.companyId);
  }

  private async submit() {
    if (!this.$refs.form.validate()) return;
    if (this.isEdit) {
      this.edit();
      return;
    }
    this.save();
  }

  private async save() {
    this.substatus
      .save()
      .then(() => {
        this.errors = [];
        this.$snackbar.open({
          text: 'Status cadastrado com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
        this.$router.push({
          name: 'sub_status-list',
        });
      })
      .catch((err) => {
        this.$snackbar.open({
          text: 'Erro ao cadastrar status',
          color: 'danger',
          buttonColor: '#fff',
        });
        this.errors = err.response.data.errors;
      });
  }

  private async edit() {
    this.substatus
      .update()
      .then(() => {
        this.errors = [];
        this.$snackbar.open({
          text: 'Status alterado com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
        this.$router.push({
          name: 'sub_status-list',
        });
      })
      .catch((err: any) => {
        this.errors = err.response.data.errors;
      });
  }

  private async goToList() {
    this.$router.push({
      name: 'sub_status-list',
    });
  }

  private async setCompanyId(companyId: string) {
    this.substatus.companyId = companyId;
  }

  private async getCompanies() {
    const companies: Company[] = await CompaniesService.getAllCompanies();
    this.companies = companies.map(company => new Company(company));
  }

  private async created() {
    this.getCompanies();
    if (this.isEdit) {
      await this.getStatusById();
    }
    this.getOrderStatus();
  }
}
